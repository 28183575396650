/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'hdd-stack': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M14 10a1 1 0 011 1v1a1 1 0 01-1 1H2a1 1 0 01-1-1v-1a1 1 0 011-1zM2 9a2 2 0 00-2 2v1a2 2 0 002 2h12a2 2 0 002-2v-1a2 2 0 00-2-2z"/><path pid="1" d="M5 11.5a.5.5 0 11-1 0 .5.5 0 011 0m-2 0a.5.5 0 11-1 0 .5.5 0 011 0M14 3a1 1 0 011 1v1a1 1 0 01-1 1H2a1 1 0 01-1-1V4a1 1 0 011-1zM2 2a2 2 0 00-2 2v1a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2z"/><path pid="2" d="M5 4.5a.5.5 0 11-1 0 .5.5 0 011 0m-2 0a.5.5 0 11-1 0 .5.5 0 011 0"/>',
    },
});
